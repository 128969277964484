// creating the slice for calling user apis

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, ImageAPI } from "../hooks/API";
import { toast } from "react-toastify";

const initialState = {
  error: false, // Initialize error state to false
  data: null,
};

export const fetchCategoryData = createAsyncThunk(
  "categoryData/fetchCategoryData",
  async () => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/category/category-data",
        header,
        "get"
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
// add the new user
export const addCategory = createAsyncThunk(
  "categoryData/addCategory",
  async (categoryData, { rejectWithValue }) => {
    try {
      const header = {
        // "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      // called imageAPI for store the image with other data
      const response = await ImageAPI(
        "/admin/category/add-category",
        header,
        "post",
        categoryData
      );

      if (response.status === 200) {
        toast.success(response.message);
        return response?.data;
      } else {
        toast.error(response.message);
        return rejectWithValue(true);
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(true);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "categoryData/updateStatus",
  async (data) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/category/update-category-status",
        header,
        "post",
        data
      );
      if (response.status== 200){
        toast.success(response.message)
      }
      if (response.status === 404) {
        toast.error(response?.message);
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categoryData/updateStatus",
  async (data) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/category/update-category",
        header,
        "post",
        data
      );
      if (response.status === 404) {
        toast.error(response?.message);
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const searchCategory = createAsyncThunk(
  "userData/searchUser",
  async (searchValue) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/category/search-category",
        header,
        "post",
        {
          searchValue,
        }
      );

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const categoryDataSlice = createSlice({
  name: "categoryData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchCategoryData.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(fetchCategoryData.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCategoryData.rejected(), (state) => {
        state.error = true;
      })
      .addCase(addCategory.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(addCategory.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(addCategory.rejected(), (state) => {
        state.error = true;
      })
      .addCase(updateStatus.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(updateStatus.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(updateStatus.rejected(), (state) => {
        state.error = true;
      })
      .addCase(searchCategory.pending(), (state) => {
        state.error = false; // Reset error state
      })
      .addCase(searchCategory.fulfilled(), (state, action) => {
        state.data = action.payload;
      })
      .addCase(searchCategory.rejected(), (state) => {
        state.error = true;
      });
  },
});

export default categoryDataSlice.reducer;
