import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { ContextProvider } from "./contexts/ContextProvider";
import { store , persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
          </PersistGate>
        </Provider>
      </ContextProvider>
      <ToastContainer 
      autoClose={2000}/>
    </BrowserRouter>
  // </React.StrictMode>
);
