import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
// import { RiNotification3Line } from "react-icons/ri";
// import { RiSunLine } from "react-icons/ri";
// import { RiMoonLine } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Cart, Chat, Notification, UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { useSelector } from "react-redux";
import userImage from "../assets/img/download.png";
const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const loginData = useSelector((state) => state?.loginUserData?.data);
  const {
    isClicked,
    handleClick,
    screenSize,
    setActiveMenu,
    setScreenSize,
    currentColor,
    currentMode,
    // setMode,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    // whenever window resizes!, use that screen size in handleResize function
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  //change mode
  // const handleChangeMode = (mode) => {
  //   setMode(mode);
  // };

  // SECTION doing something with that screensize
  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  return (
    <div className="flex justify-between md:mx-6 relative">
      <NavButton
        title="Menu"
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color={currentMode === "Light" ? currentColor : "#808080"}
        icon={<AiOutlineMenu />}
      />

      <div className="flex">
        {/* <NavButton
          title="Notifications"
          dotColor="#03C907"
          customFunc={() => handleClick("notification")}
          color={currentMode === "Light" ? currentColor : "#808080"}
          icon={<RiNotification3Line />}
        /> */}
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick("userProfile")}
          >
            <img
              src={`${loginData?.image ? loginData?.image : userImage}`}
              alt="user"
              className="rounded-full w-8 h-8 "
            />

            <p>
              <span className="text-gray-400 text-14">Hi,</span>{" "}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {loginData?.name}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent>

        {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
