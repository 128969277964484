import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { API } from "../../hooks/API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  // Get the email from the Redux state
  const email = useSelector((state) => state.email.email);

  // Define initial state for password visibility toggles
  const [newpasswordVisible, setNewPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Define useForm hook for form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Define navigate function from react-router-dom
  const navigate = useNavigate();

  // Handle form submission
  const onSubmit = async (data) => {
    const header = {
      "Content-Type": "application/json",
    };

    // Add email to form data
    data.email = email;

    // Check if new password and confirm password match
    if (data?.newPassword !== data?.confirmPassword) {
      toast.error("Your New-password and Confirm-password are not the same");
    } else {
      // Call API to reset password
      const result = await API("/reset-password", header, "post", data);

      // Handle API response
      if (result.status === 200) {
        toast.success(result.message);
        navigate("/login");
      } else {
        toast.error(result.message);
      }
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-4 md:space-y-6"
              action="#"
            >
              {/* New Password input */}
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New Password
                </label>
                <input
                  type={newpasswordVisible ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  placeholder="Please Enter Your Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-10"
                  {...register("newPassword", {
                    required: "New Password is required",
                  })}
                />
                {/* Toggle visibility icon */}
                <div
                  onClick={() => setNewPasswordVisible(!newpasswordVisible)}
                  className="absolute inset-y-0 right-0 flex items-center pr-2 pt-7 cursor-pointer"
                >
                  {newpasswordVisible ? <FaEye /> : <FaRegEyeSlash />}
                </div>
                {/* Error message for password validation */}
                {errors.newPassword && (
                  <p
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    {errors.newPassword.message}
                  </p>
                )}
              </div>

              {/* Confirm Password input */}
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm Password
                </label>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Please Enter Your Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-10"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                  })}
                />
                {/* Toggle visibility icon */}
                <div
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className="absolute inset-y-0 right-0 flex items-center pr-2 pt-7 cursor-pointer"
                >
                  {passwordVisible ? <FaEye /> : <FaRegEyeSlash />}
                </div>
                {/* Error message for password validation */}
                {errors.confirmPassword && (
                  <p
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>

              {/* Submit button */}
              <button
                type="submit"
                className="w-full h-10 rounded-lg text-white bg-cyan-500 hover:bg-cyan-600"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
