import React, { useEffect, Suspense, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import { PermissionsProvider } from "./contexts/PermissionsContext";
import { useSelector } from "react-redux";
const Layout = React.lazy(() => import("./components/Layout"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const location = useLocation();
  const loginData = useSelector((state) => state?.loginUserData?.data);
  useEffect(() => {
    const path = location.pathname;
    const lastPart = path.substring(path.lastIndexOf("/") + 1);
    const pageTitle = lastPart
      ? `Bidlyt - ${lastPart.charAt(0).toUpperCase() + lastPart.slice(1)}`
      : "Bidlyt - Dashboard";
    document.title = pageTitle;
  }, [location]);

  return (
    <PermissionsProvider userRole={loginData?.role}>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="forget-password"
            name="Forget Password Page"
            element={<ForgetPassword />}
          />
          <Route
            exact
            path="reset-password"
            name="Reset Password Page"
            element={<ResetPassword />}
          />
          <Route path="*" name="Home" element={<Layout />} />
        </Routes>
      </Suspense>
    </PermissionsProvider>
  );
};

export default App;
