import { combineReducers } from "@reduxjs/toolkit";
import emailReducer from "./emailSlice";
import loginUserReducer from "./loginUserSlice";
import userDataReducer from "./userSlice";
import loadingReducer from "./loaderSlice";
import categoryDataReducer from "./categorySlice";
import cmsDataReducer from "./cmsSlice";
import productDataReducer from "./productSlice";
const rootReducer = combineReducers({
  email: emailReducer,
  loginUserData: loginUserReducer,
  userData: userDataReducer,
  loading: loadingReducer,
  categoryData: categoryDataReducer,
  cmsData: cmsDataReducer,
  productData: productDataReducer,
});
export default rootReducer;
