import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email: '',
}
export const adminLoginSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setAdminLoginData:(state,action)=>{
            state.email = action.payload
        }
    },
  })

export const { setAdminLoginData } = adminLoginSlice.actions;
export default adminLoginSlice.reducer;