import React from "react";

const Button = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
  height,
  handleClick,
  type,
  direction,
  bottomMargin,
  leftMargin,
  disabled,
  topMargin,
}) => {
  return (
    <button
      onClick={handleClick}
      type={type ? type : "button"}
      disabled={disabled}
      style={{
        backgroundColor: bgColor,
        color,
        borderRadius,
        marginBottom: bottomMargin,
        marginLeft: leftMargin,
        marginTop: topMargin,
        size,
        height,
        display: "flex", // Set display to flex
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      }}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor} float-${direction}`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;
