// creating the slice for calling user apis
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../hooks/API";
import { ImageAPI } from "../hooks/API";
import { toast } from "react-toastify";

const initialState = {
  error: false, // Initialize error state to false
  data: null,
};
// use createAsyncThunk for calling the api in slice

// fetch user Data
export const fetchUserData = createAsyncThunk(
  "userData/fetchUserData",
  async () => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API("/admin/user/user-data", header, "get");
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// add the new user
export const addUser = createAsyncThunk(
  "userData/addUser",
  async (userData, { rejectWithValue }) => {
    try {
      const header = {
        Authorization: localStorage.getItem("token"),
      };
      // called imageAPI for store the image with other data
      const response = await ImageAPI(
        "/admin/user/add-user",
        header,
        "post",
        userData
      );

      if (response.status === 200) {
        toast.success(response.message);
        return response?.data;
      } else {
        toast.error(response.message);
        return rejectWithValue(true);
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(true);
    }
  }
);

// call Api for deleting the user
export const deleteUser = createAsyncThunk(
  "userData/deleteUser",
  async (id) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API("/admin/user/delete-user", header, "post", {
        id,
      });
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
// update the data of the existing user
export const updateUser = createAsyncThunk(
  "userData/updateUser",
  async (userData, { rejectWithValue }) => {
    try {
      const header = {
        Authorization: localStorage.getItem("token"),
      };
      const response = await ImageAPI(
        "/admin/user/update-user",
        header,
        "post",
        userData
      );

      if (response.status === 200) {
        toast.success(response.message);
        return response.data;
      } else {
        toast.error(response.message);
        return rejectWithValue(response.message); // Reject with value for error handling
      }
    } catch (error) { }
  }
);

//called an API for searching the user
export const searchUser = createAsyncThunk(
  "userData/searchUser",
  async (searchValue) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API("/admin/user/search-user", header, "post", {
        searchValue,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// called update Status API
export const updateStatus = createAsyncThunk(
  "userData/updateStatus",
  async (data) => {
    try {
      const header = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      };
      const response = await API(
        "/admin/user/update-user-status",
        header,
        "post",
        data
      );
      if (response.status === 200) {
        toast.success(response?.message)
      }
      if (response.status === 404) {
        toast.error(response?.message);
        toast.success(response?.message)
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // case for the fetchUserData API calling
      .addCase(fetchUserData.pending, (state) => {
        state.error = false; // Reset error state
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.error = true;
      })
      // case for the DeleteUser API calling
      .addCase(deleteUser.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.data = action.payload;
        // You might want to update state.data here based on your application logic
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // case for the updateUser API calling
      .addCase(updateUser.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        // You might want to update state.data here based on your application logic
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload || true; // Set error state based on payload or default to true
      })
      // case for the addUser API calling
      .addCase(addUser.pending, (state, action) => {
        state.error = false; // Reset error state
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(addUser.rejected, (state) => {
        state.error = true;
      })
      // case for the searchUser API calling
      .addCase(searchUser.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.data = action.payload;
        // You might want to update state.data here based on your application logic
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // case for the updateStatus API calling
      .addCase(updateStatus.pending, (state) => {
        state.error = null; // Reset error state
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.data = action.payload;
        // You might want to update state.data here based on your application logic
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export default userDataSlice.reducer;
