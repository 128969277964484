// PermissionsContext.js
import React, { createContext, useContext } from "react";

const PermissionsContext = createContext();

export const usePermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ userRole, children }) => {
  const rolesPermissions = {
    FULL_ACCESS: ["add", "edit", "delete", "view", "status"],
    VIEWER_ONLY: ["view"],
  };
  const hasPermission = (action) =>
    rolesPermissions[userRole]?.includes(action);
  return (
    <PermissionsContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};
