import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { BsLockFill, BsPersonCircle } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { Button } from ".";
import userImage from "../assets/img/download.png"


const userProfileData = [
  {
    icon: <BsLockFill />,
    title: "Change Password",
    route: "/change-password",
    desc: "Account Settings",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
  },
  {
    icon: <BsPersonCircle />,
    title: "Update Profile",
    desc: "Account Settings",
    route: "/update-profile",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
  },
];

const UserProfile = () => {
  // use useStateContext to change the state of setIsClicked to redirect the login page
  const { setIsClicked, initialState } = useStateContext();
  const { currentColor } = useStateContext();
  const loginData = useSelector((state) => state?.loginUserData?.data);

  const navigate = useNavigate();
  // use to close the component after clicking outside the component
  const ref = useRef(null);
  useOutsideAlerter(ref, setIsClicked, initialState);

  // this function is used to log out from the dashBoard
  const handleClick = () => {
    localStorage.removeItem("token");
    setIsClicked(initialState);
  };
  // close the tab by the cross icon
  const handleOffTheProfileTab = () => {
    setIsClicked(initialState);
  };

  return (
    <div
      className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96"
      ref={ref}
    >
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200"></p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          handleClick={handleOffTheProfileTab}
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          // src={`${process.env.REACT_APP_BASE_URL}/${loginData?.image}`}
          src={`${loginData?.image ? loginData?.image : userImage}`}

          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {loginData.name}{" "}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {loginData?.email}{" "}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
            onClick={() => {
              navigate(item?.route);
              handleOffTheProfileTab();
            }}
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
          handleClick={() => handleClick()}
        />
      </div>
    </div>
  );
};

function useOutsideAlerter(ref, setIsClicked, initialState) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClicked(initialState);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, initialState]);
}

export default UserProfile;
