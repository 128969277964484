import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isloading: false,
}
export const adminLoadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading:(state,action)=>{
            state.isloading = action.payload
        }
    },
  })

export const { setLoading } = adminLoadingSlice.actions;
export default adminLoadingSlice.reducer;